/* You can add global styles to this file, and also import other style files */
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~leaflet/dist/leaflet.css';
@import "~nouislider/dist/nouislider.min.css";

html, body { height: 100%; }
body { 
  margin: 0;

  .cdk-overlay-container {
    z-index: 2000;
  }
}


.popup-panel-white-glass-background {
 .mat-dialog-container {
    background: rgba(255, 255, 255, 0.50);
	-webkit-backdrop-filter: blur(20px) brightness(160%);
    backdrop-filter: blur(20px) brightness(160%);
	box-shadow: 0px 0px transparent;
  }
  @supports (-moz-appearance:none) {
    .mat-dialog-container {
		background: #EDEDED;
	}
  }
  _:-ms-lang(x), _:-webkit-full-screen { .mat-dialog-container  {
    background: #EDEDED;
  }
  }
}


.timeslider .slider.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  margin-right: 18px;
}

.timeslider {
  .noUi-handle {
    cursor: grab;

    &.noUi-active {
      cursor: grabbing;
    }
  }
}

.hidden-placeholder {
  display: none;
}

.leaflet-non-interactive-layer {
  pointer-events: none;
}

.vg-tooltip {
  z-index: 5000 !important;
}

.hidden-translation {
  display: none;
}